<template>
  <div
    class="hero-banner home-hero-banner"
    :style="{
      backgroundImage: `linear-gradient(to right, rgba(241, 215, 106, 0.75) 60%, rgba(51, 162, 143, 0.6)), url(${pageContent.data.attributes.BannerImage.data.attributes.url})`,
    }"
  >
    <div
      class="hero-content content-container-1140 d-flex flex-column justify-center fill-height"
    >
      <div class="home-hero-content-inner">
        <h1>{{ pageContent.data.attributes.BannerTitle }}</h1>
      </div>
    </div>
  </div>
  <div class="home-main-content" id="home-page">
    <div class="content-container-1140">
      <div class="home-content-explorer">
        <div class="home-content-col">
          <h2 text-color="white" class="text-white pb-5">
            {{ pageContent.data.attributes.PathwaysExplorerTitle }}
          </h2>
          <div
            class="md-content"
            v-html="md.render(pageContent.data.attributes.PathwaysExplorerText)"
          ></div>
          <nuxt-link
            class="btn-red btn-bg-white"
            :to="
              localePath({
                name: 'explore-region-country-sector',
                params: { region: 'SA', country: $t('explorer.all') },
              })
            "
            >{{ pageContent.data.attributes.PathwaysExplorerButton }}</nuxt-link
          >
        </div>
        <div class="home-content-image-col">
          <mapbox-map
            container="map"
            :year="currentYear"
            :attribution-control="false"
            ref="map"
            @update:region="launchExplorerFromMap"
          ></mapbox-map>
        </div>
      </div>
      <div class="home-content-solutions">
        <div class="home-content-col">
          <h2 text-color="white" class="text-white pb-5">
            {{ pageContent.data.attributes.PathwaysSolutionsTitle }}
          </h2>
          <div
            class="md-content"
            v-html="
              md.render(pageContent.data.attributes.PathwaysSolutionsText)
            "
          ></div>
        </div>
        <div class="home-image-col">
          <!-- sector links -->
          <sector-solutions-link-list></sector-solutions-link-list>
        </div>
      </div>
      <div
        class="home-carousel-container"
        :class="{ 'showing-arrows': solutions.data.length > 3 }"
      >
        <v-slide-group show-arrows>
          <v-slide-group-item
            v-for="(solution, index) in solutions.data"
            :key="index"
          >
            <div
              class="slide-group-item-inner"
              :class="`slide-group-item-inner-${solution.attributes.SolutionSector}`"
            >
              <nuxt-link
                :to="
                  localeRoute({
                    name: 'solutions-SolutionURL',
                    params: { SolutionURL: solution.attributes.SolutionURL },
                  }).fullPath
                "
                external
              >
                <v-img
                  :src="
                    solution.attributes.SolutionImage.data.attributes.formats
                      .medium.url
                  "
                  cover
                ></v-img>
                <v-icon
                  :icon="`$${sectors[solution.attributes.SolutionSector].icon}`"
                ></v-icon>
                <div class="slide-group-item-title">
                  {{ solution.attributes.SolutionTitle }}
                </div>
              </nuxt-link>
            </div>
          </v-slide-group-item>
        </v-slide-group>
        <div class="d-flex justify-center">
          <nuxt-link
            class="btn-green btn-bg-white"
            :to="localePath({ name: 'solutions_overview-sector' })"
            >{{
              pageContent.data.attributes.PathwaysSolutionsButton
            }}</nuxt-link
          >
        </div>
      </div>
      <div class="home-content-methane">
        <div class="home-content-col">
          <h2 text-color="white" class="text-white pb-5">
            {{ pageContent.data.attributes.PathwaysMethaneTitle }}
          </h2>
          <div
            class="md-content"
            v-html="md.render(pageContent.data.attributes.PathwaysMethaneText)"
          ></div>
          <nuxt-link
            class="btn-yellow btn-bg-white"
            :to="localePath('methane101')"
            >{{ pageContent.data.attributes.PathwaysMethaneButton }}</nuxt-link
          >
        </div>
        <div class="home-image-col">
          <nuxt-img
            :src="
              pageContent.data.attributes.PathwaysMethaneImage.data.attributes
                .url
            "
            :alt="
              pageContent.data.attributes.PathwaysMethaneImage.data.attributes
                .alternativeText
            "
          ></nuxt-img>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
const { locale, t } = useI18n();
const { md } = useMarkdownIt();
const localePath = useLocalePath();
const localeRoute = useLocaleRoute();
const router = useRouter();
const { sectors, scenarios } = usePathwaysConfig();

useHead({
  title: t('navigation.routeTitles.home')
})

// ref for the mapbox map
const map = ref(null);
// the data year to display
const currentYear = ref(new Date().getFullYear());

//const solutionsRoute = localeRoute({ name: "solutions" });
const solutionsRoute = computed(() => localeRoute({ name: "solutions" }));

function getRandomSubarray(arr, size) {
  const shuffled = arr.slice(0);
  let i = arr.length;
  const min = i - size;
  while (i-- > min) {
    const index = Math.floor((i + 1) * Math.random());
    [shuffled[i], shuffled[index]] = [shuffled[index], shuffled[i]];
  }
  return shuffled.slice(min);
}

// api content
const contentType = ["homepage", "solutions"];
const key = [
  `${contentType[0]}_${locale.value}`,
  `${contentType[1]}_cards_${locale.value}`,
];

const apiURL = [
  getStrapiLink(contentType[0], {
    populate: ["BannerImage", "PathwaysExplorerImage", "PathwaysMethaneImage"],
  }),
  getStrapiLink(contentType[1], {
    fields: ["SolutionSector", "SolutionURL", "SolutionTitle"],
    populate: ["SolutionImage"],
  }),
];

// when multiple useFetch calls are required, they need to be put inside a Promise.all() (see https://github.com/nuxt/nuxt/issues/14725#issuecomment-1397364309 example)
const [{ data: pageContent }, { data: solutions }] = await Promise.all([
  useFetch(apiURL[0], {
    key: key[0],
    watch: false,
  }),
  useFetch(apiURL[1], {
    key: key[1],
    watch: false,
  }),
]);

const randomSolutions = computed(() => {
  if (solutions.value.data && solutions.value.data.length >= 2) {
    // Make a copy to avoid modifying original array
    const solutionsCopy = [...solutions.value.data];
    const pickedSolutions = [];
    for (let i = 0; i < 2; i++) {
      const randomIndex = Math.floor(Math.random() * solutionsCopy.length);
      pickedSolutions.push(solutionsCopy[randomIndex]);
      solutionsCopy.splice(randomIndex, 1);
    }

    return pickedSolutions;
  } else {
    return solutions.value;
  }
});

onMounted(() => {
  if (map.value !== null) {
    map.value.updateMapCH4Data(scenarios.bau.data);
    map.value.flyToSA();
    setTimeout(map.value.flyToSA(), 1000);
  }
});

const launchExplorerFromMap = (regionId) => {
  navigateTo(
    localePath({
      name: "explore-region-country-sector",
      params: { region: "SA", country: regionId },
    })
  );
};
</script>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      carouselWidth: null as null | number,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.carouselWidth = this.$refs.carousel?.$el?.offsetWidth ?? null;
    });
  },
});
</script>

<style lang="scss" scoped>
.hero-banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}
.home-hero-banner {
  padding: 180px 20px 112px;
  // Full height banner minus header height
  height: calc(100vh - 135px);

  @media (max-width: 767px) {
    padding: 90px 20px;
  }
}
.home-hero-content-inner {
  max-width: 730px;

  h1 {
    font-size: 50px;
    font-weight: 800;
    line-height: 1.2em;

    @media (max-width: 768px) {
      font-size: 35px;
    }
  }
}
.home-main-content {
  background-color: #e8f7f4;
  padding: 150px 20px 100px;

  @media (max-width: 768px) {
    padding: 75px 20px;
  }
}
// Home Explorer Content
.home-content-methane,
.home-content-solutions,
.home-content-explorer {
  display: flex;
  align-items: center;
  margin-bottom: 120px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  .home-content-col {
    z-index: 2;
    padding: 40px;
    width: 100vw;
    max-width: 650px;
    margin-right: -30px;

    @media (max-width: 768px) {
      max-width: unset;
      width: 100%;
      margin-right: 0px;
    }

    h2 {
      font-size: 39px !important;
      font-weight: 600;
      line-height: 1.18em;
    }
  }
  .home-image-col {
    width: 100%;
    margin-left: -30px;

    @media (max-width: 768px) {
      max-width: unset;
      margin-left: 0px;
    }
  }
}
.home-content-image-col {
  height: 100%;
  width: 100%;
}
.home-content-explorer .home-content-col {
  background-color: #cb5546;
}
.home-content-solutions {
  .home-content-col {
    background-color: #33a28f;
  }
  .home-image-col {
    background-color: #ffffff;
    padding: 40px 40px 40px 15%;
  }
  ul {
    list-style: none;
  }
}
.home-content-methane {
  flex-direction: row-reverse;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  .home-content-col {
    background-color: #f1d76a;
    max-width: 550px;
    margin-right: 0;
    margin-left: -30px;

    @media (max-width: 768px) {
      max-width: unset;
      margin-left: 0px;
    }
  }
  .home-image-col {
    margin-left: 0px;
    margin-right: -30px;
    height: 450px !important;

    @media (max-width: 768px) {
      max-width: unset;
      margin-right: 0px;
    }
    img {
      height: 100%;
    }
  }
  h2,
  a {
    color: #050505 !important;
  }
}

// Home Carousel
@media screen and (min-width: 1025px) {
  .home-carousel-container.showing-arrows {
    max-width: 1084px;
    .v-slide-group {
      width: 1084px;
    }
  }
}
.home-carousel-container {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;

  .v-img {
    height: 100%;
  }

  .slide-group-item-title {
    position: absolute;
    bottom: 0;
    z-index: 5;
    width: 100%;
    padding: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: rgba(0, 0, 0, 0.87);
  }
  .slide-group-item-title:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
  // Color variations
  .slide-group-item-inner-waste {
    .v-icon {
      background-color: #cb5546b3;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      left: 0;
      color: #050505;
      font-size: 20px;
    }

    :deep(.svg-inline--fa) {
      width: 24px;
      height: 24px;
    }
    .slide-group-item-title {
      background-color: #cb5546;

      &:before {
        border-bottom: 10px solid #cb5546;
      }
    }
  }
  .slide-group-item-inner-energy {
    .v-icon {
      background-color: #f1d76ab3;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      left: 0;
      color: #050505;
      font-size: 20px;
    }

    :deep(.svg-inline--fa) {
      width: 24px;
      height: 24px;
    }
    .slide-group-item-title {
      background-color: #f1d76a;
      &:before {
        border-bottom: 10px solid #f1d76a;
      }
    }
  }
  .slide-group-item-inner-agriculture {
    .v-icon {
      background-color: #33a28fb3;
      height: 40px;
      width: 40px;
      position: absolute;
      top: 0;
      left: 0;
      color: #050505;
      font-size: 20px;
    }

    :deep(.svg-inline--fa) {
      width: 24px;
      height: 24px;
    }
    .slide-group-item-title {
      background-color: #33a28f;
      &:before {
        border-bottom: 10px solid #33a28f;
      }
    }
  }
}
// Responsiveness
.v-slide-group {
  width: 980px;
  max-height: 300px;
  padding-left: -30px;
  padding-right: -30px;
  margin: 0 auto;
}
.slide-group-item-inner {
  /* container size divided by 3, minus margin */
  width: calc((980px / 3) - 60px);
  height: 300px;
  position: relative;
  margin: 0 30px;
}
@media screen and (max-width: 1024px) {
  .v-slide-group {
    width: 720px;
  }
  .slide-group-item-inner {
    /* container size minus arrows width, divided by 2, minus margin */
    width: calc((720px - 104px) / 2) - 40px;
    height: 300px;
    position: relative;
    margin: 0 20px;
  }
}

@media screen and (max-width: 768px) {
  .v-slide-group {
    width: 480px;
  }
  .slide-group-item-inner {
    /* container size minus arrows width, divided by 2, minus margin */
    width: calc((480px - 104px) - 40px);
    height: 300px;
    position: relative;
    margin: 0 20px;
  }
}

@media screen and (max-width: 767px) {
  .v-slide-group {
    width: 350px;
  }
  .slide-group-item-inner {
    /* container size minus arrows width, divided by 2, minus margin */
    width: calc((350px - 72px) - 20px);
    height: 300px;
    position: relative;
    margin: 0 10px;
  }
  .v-slide-group__prev,
  .v-slide-group__next {
    min-width: 36px;
  }
}
.btn-bg-white {
  padding: 10px 18px;
  margin-top: 40px;
}

// Landing Page
.default-layout-container {
  padding: 100px 20px;

  @media (max-width: 1023px) {
    padding: 50px 20px;
  }

  h1 {
    color: #cc5546;
    font-family: "Hind";
    font-weight: 700;
    font-size: 60px;
    line-height: 1.1em;

    @media (max-width: 1023px) {
      font-size: 50px;
      margin-bottom: 35px;
    }
  }
}

// map container
#map {
  background-color: #fff;
  height: 400px !important;
  margin-left: -50px;
  padding-right: 50px;
  :deep(.mapboxgl-ctrl-logo) {
    display: none !important;
  }
  @media (max-width: 768px) {
    margin-left: 0;
    padding-right: 80px;
  }
  :deep(h2) {
    font-size: 40px;
    color: #33a390;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  :deep(h4) {
    color: #247775;
  }
}
</style>
